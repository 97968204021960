import React from 'react';
import {
  Button,
  ColoredSection,
  Header,
  HeroImage,
  LayoutUI,
  LeadText,
  Stack,
  Subheader,
  TextStyle,
  TwoUp
} from '../../../ui-kit';
import ROUTES from '../../../utils/routes';

import HeaderImage from '../../../assets/images/speaker-series/header.jpg';

import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';

import * as styles from './LiveEvents.module.scss';

import FourUp1 from '../../../assets/images/speaker-series/4-up-1.jpg';
import FourUp2 from '../../../assets/images/speaker-series/4-up-2.jpg';
import FourUp3 from '../../../assets/images/speaker-series/4-up-3.jpg';
import FourUp4 from '../../../assets/images/speaker-series/4-up-4.jpg';

const CONTENT = {
  SAMPLE_EVENTS: [
    {
      text: 'With Doug Abrams',
      image: FourUp1
    },
    {
      text: 'Trinidad and Tobago',
      image: FourUp2
    },
    {
      text: 'TedxMission',
      image: FourUp3
    },
    {
      text: 'Interviewing Chade-Meng Tan',
      image: FourUp4
    }
  ]
};

const LiveEvents = () => (
  <Layout>
    <Navbar color="red" />
    <MobileNavbar />
    <HeroImage image={HeaderImage} height="large" />

    <LayoutUI narrow>
      <TextStyle center>
        <Stack vertical spacing="loose">
          <Header>Experience Randy Taran Live</Header>
          <LeadText>
            Award-winning director and speaker, Randy Taran, has been on an
            international speaking circuit since 2009 – speaking everywhere from
            the TEDx stage, to being invited by the First Lady of Guyana to
            present happiness skills country-wide.
          </LeadText>
        </Stack>
      </TextStyle>
    </LayoutUI>

    <div className={styles.SampleEventContainer}>
      {CONTENT.SAMPLE_EVENTS.map(({ text, image }) => (
        <div className={styles.SampleEvent} key={text}>
          <img src={image} alt="" className={styles.SampleEventImage} />

          <div className={styles.SampleEventOverlay}>
            <Header size="small">{text}</Header>
          </div>
        </div>
      ))}
    </div>

    <div>
      <TwoUp>
        <div className={styles.CTASectionGray}>
          <Stack vertical>
            <Subheader>Speaker Series</Subheader>
            <Header size="small">Elevate Your Event</Header>
            <p>
              Invite Randy Taran to your event, and inspire your audience to
              bring lasting change! Speak to our event specialists today.
            </p>
            <div className={styles.CTAButton}>
              <Button
                href={ROUTES.speakerSeriesRequest()}
                color="orange"
                secondary
              >
                Contact Us
              </Button>
            </div>
          </Stack>
        </div>
        <div className={styles.CTASection}>
          <Stack vertical>
            <Subheader>Film Screening</Subheader>
            <Header size="small">Host a Film Screening</Header>
            <p>
              Share our award-winning documentary on the most basic universal
              quest: happiness. Bring it to your community today!
            </p>
            <div className={styles.CTAButton}>
              <Button href={ROUTES.filmScreeningRequest()} color="orange">
                Contact Us
              </Button>
            </div>
          </Stack>
        </div>
      </TwoUp>
    </div>

    <ColoredSection color="orange-light">
      <LayoutUI>
        <div className={styles.Footer}>
          <TextStyle center color="white">
            <Header size="small">Collaborate with Project Happiness!</Header>
            <Header size="small">
              Say Hi &rarr;
              <a href="mailto:events@projecthappiness.org">
                events@projecthappiness.org
              </a>
            </Header>
          </TextStyle>
        </div>
      </LayoutUI>
    </ColoredSection>
  </Layout>
);

export default LiveEvents;
